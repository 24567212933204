<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="500"
    :title="$t('customer.newOrderTypeDialog.title')"
    closeable
  >
    <v-list>
      <v-list-item @click="$emit('on-select', 'upload-order')">
        <v-list-item-icon>
          <cz-icon :src="mdiUploadOutline" :size="32" />
        </v-list-item-icon>
        <v-list-item-title>{{ $t('customer.uploadOrder') }}</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item @click="$emit('on-select', 'order')">
        <v-list-item-icon>
          <cz-icon :src="mdiFilePlusOutline" :size="32" />
        </v-list-item-icon>
        <v-list-item-title>{{
          $t('customer.createManualOrder')
        }}</v-list-item-title>
      </v-list-item>
      <template v-if="orderItemsCreationIsActive">
        <v-divider />
        <v-list-item @click="$emit('on-select', 'order-items')">
          <v-list-item-icon>
            <cz-icon :src="mdiInvoiceListOutline" :size="32" />
          </v-list-item-icon>
          <v-list-item-title>{{
            $t('customer.createManualOrderWithItems')
          }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </cz-dialog>
</template>

<script>
import {
  mdiUploadOutline,
  mdiFilePlusOutline,
  mdiInvoiceListOutline
} from '@mdi/js';
import { CzDialog, CzIcon } from '@/components';
export default {
  name: 'NewOrderTypeDialog',
  components: {
    CzDialog,
    CzIcon
  },
  props: {
    orderItemsCreationIsActive: {
      type: Boolean
    }
  },
  data() {
    return {
      mdiInvoiceListOutline,
      mdiUploadOutline,
      mdiFilePlusOutline
    };
  }
};
</script>

<style lang="scss" scoped></style>
