import ACTIVE_FEATURE from '../types/ActiveFeature';

export const isActiveFeatureEnabled = (feature, activeFeatures) => {
  if (!feature || !activeFeatures?.length) {
    return false;
  }

  return activeFeatures.includes(feature);
};

export const isDigitalArchiveEnabled = (account) => {
  const digitalArchiveFeatures = [
    ACTIVE_FEATURE.DIGITAL_ARCHIVE_ORDERS,
    ACTIVE_FEATURE.DIGITAL_ARCHIVE_SUBMISSION
  ];

  for (const feature of digitalArchiveFeatures) {
    if (account?.activeFeatures?.includes(feature)) {
      return true;
    }
  }

  return false;
};
